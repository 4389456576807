import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

//Components
import Layout from '../components/layout'

//Styles
import '../../node_modules/flag-icon-css/sass/flag-icon.scss'
import * as hero from '../styles/Hero.module.scss'
import * as styles from '../styles/Conf.module.scss'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

const Template = ({ pageContext: { locale }, data, location }) => {
  const { datoCmsCountry: country } = data
  const country_code = `flag-icon ${styles.flagIcon} flag-icon-${country.countryCode}`

  const queryUrl = location.search
  const checkUrl = queryUrl ? queryUrl : ''
  const backUrl = `/conference${checkUrl}`
  let queryVal
  let splitVal
  if (!checkUrl.length > 0) {
    queryVal = 'Your code'
  } else {
    splitVal = checkUrl.split('=')
    queryVal = splitVal[1]
  }
  if (country.countryCode === "it") {
    country.number = "+39 073 3193 1511";
  }

  const confCode = queryVal
  const ifSweden =
    country.path === 'sweden' ? (
      <div className={styles.item}>
        <span className={`material-icons ${styles.materialIcons}`}>info</span>
        <p>
          <FormattedHTMLMessage id="sweden_info" />
        </p>
      </div>
    ) : (
      ''
    )
  return (
    <Layout locale={locale}>
      <section className={`${hero.heroImg} ${hero.heroSmall}`}>
        <GatsbyImage
          className={hero.fixedHeight}
          alt=""
          image={data.file.childImageSharp.gatsbyImageData}
        />

        <div className={`${styles.containerConf}`}>
          <div className={`${styles.item} ${styles.topSmall}`}>
            <Link to={`/${locale}${backUrl}`}>
              <span className={`material-icons ${styles.materialIcons}`}>
                arrow_back
              </span>
              <p>
                <FormattedMessage id="goback" />
              </p>
            </Link>
          </div>
        </div>
        <div
          className={`${styles.item} ${styles.mqSmall} ${styles.bottomSmall}`}
        >
          <span className={country_code} />
          <h2>{country.name}</h2>
        </div>
      </section>

      <section className={`${styles.grid} ${styles.number}`}>
        <div className={`${styles.item} ${styles.mqBig}`}>
          <span className={country_code} />
          <h2>{country.name}</h2>
        </div>
        {ifSweden}
        <div className={styles.item}>
          <span className={`material-icons ${styles.materialIcons}`}>
            attach_money
          </span>
          <p>
            <FormattedMessage id="price" />
          </p>
        </div>

        <div className={styles.item}>
          <h3>
            <FormattedMessage id="how_to_join" />
          </h3>
        </div>
        <div className={styles.item}>
          <span className={`material-icons ${styles.materialIcons}`}>
            phone
          </span>
          <p>
            <FormattedMessage id="dial" />
            <a href={`tel:${country.number}`}>{country.number}</a>
          </p>
        </div>

        <div className={styles.item}>
          <span className={`material-icons ${styles.materialIcons}`}>lock</span>
          <p>
            <FormattedMessage id="code" />
            <strong>{confCode}</strong>
          </p>
        </div>
        <div className={styles.item}>
          <span className={`material-icons ${styles.materialIcons}`}>
            phone_iphone
          </span>
          <p>
            <FormattedMessage id="mobile_dial" />
            <a
              href={`tel:${country.number}${confCode}`}
            >{`${country.number} ${confCode}`}</a>
          </p>
        </div>
      </section>
    </Layout>
  )
}

Template.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default Template

export const tempQuery = graphql`
  query tempQuery($slug: String!, $locale: String!) {
    datoCmsCountry(path: { eq: $slug }, locale: { eq: $locale }) {
      name
      path
      countryCode
      number
      locale
    }
    file(relativePath: { eq: "background-conf.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, height: 400, placeholder: BLURRED)
      }
    }
  }
`
